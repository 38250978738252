import React, { Fragment, type ReactNode } from 'react';

import { useId } from '@atlaskit/ds-lib/use-id';

import { MenuListItem } from '../menu-item/menu-list-item';

import { MenuSectionContext } from './menu-section-context';

type MenuSectionProps = {
	children?: ReactNode;
	/**
	 * Wraps the `MenuSection` in a `MenuListItem` so that it can validly be the child of a `MenuList`
	 *
	 * In the future this will become the default behavior, and the prop will be removed.
	 */
	isMenuListItem?: boolean;
};

/**
 * __MenuSection__
 *
 * A composable component for grouping menu items, along with a heading (`MenuSectionHeading`) and a divider (`Divider`).
 *
 * Usage example:
 * ```jsx
 * <MenuSection>
 *   <MenuSectionHeading>Section heading</MenuSectionHeading>
 *   <MenuList>
 *     <MenuItem>Item 1</MenuItem>
 *     <MenuItem>Item 2</MenuItem>
 *   </MenuList>
 *   <Divider />
 * </MenuSection>
 * ```
 */
export const MenuSection = ({
	children,
	isMenuListItem = false,
}: MenuSectionProps): JSX.Element => {
	const id = useId();

	const Wrapper = isMenuListItem ? MenuListItem : Fragment;

	return (
		<MenuSectionContext.Provider value={id}>
			<Wrapper>
				<div role="group" aria-labelledby={`${id}-heading`}>
					{children}
				</div>
			</Wrapper>
		</MenuSectionContext.Provider>
	);
};
