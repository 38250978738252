import { useContext, useEffect, useCallback } from 'react';
import { useQuery } from 'react-apollo';

import { useSessionData, useIsLicensed } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';
import { fg } from '@confluence/feature-gating';

import {
	useGetOnboardingState,
	useSetOnboardingState,
	deserializeState,
} from '../onboardingState/onboardingState';
import { EDITOR_ONBOARDING_STATE_KEYS } from '../../constants/onboarding-state/onboarding-editor-constants';
import { ONBOARDING_INTENT_EXPERIENCES } from '../../constants/onboarding-state/onboarding-constants';
import { useMigratedUser } from '../useMigratedUser/useMigratedUser';
import { useIsEstablishedUserTraitName } from '../useIsEstablishedUserTraitName';

import { UserPersonalizationQuery } from './UserPersonalizationQuery.experimentalgraphql';
import type {
	PersonalizationQuery,
	PersonalizationQueryVariables,
} from './__types__/UserPersonalizationQuery';

interface SetOnboardingEligibilityProps {
	/* Onboarding state key that indicates if a user is eligible for an experiment */
	onboardingStateEligibleValue: boolean;
	/* Boolean for if the feature flag is enabled */
	featureFlagEnabled: boolean;
	/* Onboarding state key that will be set to `true`*/
	onboardingStateEligibleKey: string;
}

export const useOnboardingEligible = () => {
	const { isNewUser } = useContext(SPAViewContext);
	const { userId } = useSessionData();
	const { isMigratedUser, isMigratedUserReady } = useMigratedUser();
	const { setOnboardingState } = useSetOnboardingState();
	const isLicensed = useIsLicensed();
	const eligibleNewUser = isLicensed && isNewUser;
	const isEstablishedUserTraitName = useIsEstablishedUserTraitName();

	/**
	 * Skip the query for checking whether the user is an established user if:
	 * 1. The master onboarding FF is OFF
	 * 2. The user is not licensed
	 * 3. The user is not logged in (anonymous)
	 * 4. The user is not new (hasn't viewed a confluence page)
	 */
	const skipQuery = !eligibleNewUser || !fg('cc_onboarding_experience');

	const {
		data: userPersonalizationQueryData,
		loading: userPersonalizationQueryLoading,
		error: userPersonalizationQueryError,
	} = useQuery<PersonalizationQuery, PersonalizationQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		UserPersonalizationQuery,
		{
			variables: {
				userId: userId!,
			},
			skip: skipQuery,
		},
	);

	const attributes =
		userPersonalizationQueryData?.experimentalUserPersonalization?.attributes ?? [];
	// if we skip querying for personalization,
	// we can assume that user should be establishedUser and skip over onboarding
	const isEstablishedUser =
		skipQuery ||
		attributes.find((attr) => attr?.name === isEstablishedUserTraitName)?.value === 'true';

	const isEstablishedUserReady = !userPersonalizationQueryLoading && !userPersonalizationQueryError;

	const {
		data: onboardingStateData,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		[
			...Object.values(EDITOR_ONBOARDING_STATE_KEYS),
			...Object.values(ONBOARDING_INTENT_EXPERIENCES),
		],
		!isLicensed || !fg('cc_onboarding_experience'),
	);

	const { editorOnboardingEligiblity, isOnboardingExperienceEligibleUser } =
		deserializeState(onboardingStateData);

	const checkAndSetOnboardingEligibility = useCallback(
		({
			onboardingStateEligibleValue,
			featureFlagEnabled,
			onboardingStateEligibleKey,
		}: SetOnboardingEligibilityProps) => {
			if (
				!isEstablishedUser &&
				isEstablishedUserReady &&
				onboardingStateData &&
				featureFlagEnabled &&
				!onboardingStateEligibleValue
			) {
				void setOnboardingState({
					key: onboardingStateEligibleKey,
					value: 'true',
				});
			}
		},
		[isEstablishedUser, onboardingStateData, setOnboardingState, isEstablishedUserReady],
	);

	// IBO Tagging
	useEffect(() => {
		checkAndSetOnboardingEligibility({
			onboardingStateEligibleValue: isOnboardingExperienceEligibleUser,
			featureFlagEnabled: true,
			onboardingStateEligibleKey: ONBOARDING_INTENT_EXPERIENCES.ONBOARDING_EXPERIENCE_ELIGIBLE,
		});
	}, [isOnboardingExperienceEligibleUser, checkAndSetOnboardingEligibility]);

	// Editor Onboarding Experiment
	useEffect(() => {
		if (userPersonalizationQueryData && onboardingStateData && fg('cc_onboarding_experience')) {
			// only check for users with Eligibility not set
			if (editorOnboardingEligiblity === null || editorOnboardingEligiblity === undefined) {
				if ((isMigratedUserReady && isMigratedUser) || isEstablishedUser) {
					// Set EDITOR_ONBOARDING_ELIGIBLE_NEW: false for migrated users and established users
					void setOnboardingState({
						key: EDITOR_ONBOARDING_STATE_KEYS.EDITOR_ONBOARDING_ELIGIBLE_NEW,
						value: 'false',
					});
				} else {
					// Set EDITOR_ONBOARDING_ELIGIBLE_NEW: true for users that are new to confluence
					void setOnboardingState({
						key: EDITOR_ONBOARDING_STATE_KEYS.EDITOR_ONBOARDING_ELIGIBLE_NEW,
						value: 'true',
					});
				}
			}
		}
	}, [
		eligibleNewUser,
		isEstablishedUser,
		isMigratedUserReady,
		isMigratedUser,
		editorOnboardingEligiblity,
		userPersonalizationQueryData,
		onboardingStateData,
		onboardingStateLoading,
		onboardingStateError,
		setOnboardingState,
	]);

	return {
		isEstablishedUser,
		isEstablishedUserReady,
		loading: userPersonalizationQueryLoading,
		error: userPersonalizationQueryError,
		skipQuery,
		eligibleNewUser,
	};
};
